<template>
  <v-container id="cupones" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-ticket-percent"
      inline
      :title="$t('coupons')"
    >
      <v-spacer></v-spacer>

      <!-- Boton nuevo registro -->
      <template v-slot:after-heading></template>
      <template v-slot:corner-button>
        <v-btn color="primary" elevation="2" small class="ma-2" @click.stop="dialog = true"
          >Nuevo Cupón</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="red"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Nuevo Agregar/Editar Cupón -->
      <v-dialog max-width="500px" v-model="dialog">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- cupón -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Cupon*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.cupon.$model"
                    :disabled="editedIndex !== -1"
                    :error-messages="cuponErrors"
                    @blur="$v.editedItem.cupon.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.cupon.$reset()"
                    @keypress="isAlphaNumeric($event)"
                  ></v-text-field>
                </v-col>

                <!-- cantidad -->
                <!-- <v-col cols="12">
                  <v-text-field
                    dense
                    label="Cantidad*"
                    maxlength="2"
                    required
                    v-model="$v.editedItem.cantidad.$model"
                    :error-messages="cantidadErrors"
                    @blur="$v.editedItem.cantidad.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.cantidad.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col> -->

                <v-col cols="12">
                  <v-slider
                    label="Cantidad"
                    max="100"
                    min="0"
                    step="5"
                    thumb-color="error"
                    thumb-label="always"
                    ticks
                    v-model="$v.editedItem.cantidad.$model"
                  ></v-slider>
                </v-col>

                <!-- Fecha Inicio Validez -->
                <v-col cols="6">
                  <v-layout row wrap>
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Válido desde*"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          required
                          v-on="on"
                          :error-messages="desdeErrors"
                          :value="fromDateDisp"
                          @blur="$v.editedItem.desde.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-MX"
                        no-title
                        v-model="$v.editedItem.desde.$model"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onTextChange"
                        @input="fromDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-layout>
                </v-col>

                <!-- Fecha Final Validez -->
                <v-col cols="6">
                  <v-layout row wrap>
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="toDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Válido Hasta"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          :value="toDateDisp"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-MX"
                        no-title
                        v-model="editedItem.hasta"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onTextChange"
                        @input="toDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-layout>
                </v-col>

                <!-- Tipo de descuento -->
                <v-col cols="6">
                  <v-switch v-model="editedItem.porcentaje" :label="`Porcentaje`"></v-switch>
                </v-col>

                <!-- Uso único -->
                <v-col cols="6">
                  <v-switch v-model="editedItem.unico" :label="`Un solo uso`"></v-switch>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo obligatorio</small>
          </v-card-text>

          <!-- Botones y Crear Otro -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de cupones -->

      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="cupones"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- <template v-slot:[`item.cantidad`]="{ item }">
          <DateFormat :number="item.cantidad" />
        </template> -->

        <!-- Slot Porcentaje -->
        <template v-slot:[`item.porcentaje`]="{ item }">
          <v-icon v-if="item.porcentaje">mdi-percent</v-icon>
          <v-icon v-else>mdi-currency-usd</v-icon>
        </template>

        <!-- Slot Uso Único -->
        <template v-slot:[`item.unico`]="{ item }">
          <v-icon v-if="item.unico">mdi-checkbox-outline</v-icon>
          <v-icon v-else>mdi-checkbox-multiple-outline</v-icon>
        </template>

        <!-- Slot de fechas-->
        <template v-slot:[`item.desde`]="{ item }">
          <DateFormat :begin="item.desde" :end="item.hasta" />
        </template>

        <!-- Slot de Acciones-->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <template v-slot:no-data>¡No hay datos para mostrar!</template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter
        :dataArray="cupones"
        :dataFields="fields"
        fileName="Cupones"
      /> </base-material-card
  ></v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import DateFormat from '@/components/general/DateFormat.vue'
import Status from '@/components/general/StatusInfo.vue'
export default {
  name: 'Cupones',
  components: {
    DateFormat,
    ConfirmationDialog,
    DataExporter,
    Status,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    fromDateMenu: false,
    isEditing: false,
    isLoading: false,
    isSure: false,
    minDate: new Date().toISOString().substr(0, 10),
    overlay: false,
    search: undefined,
    toDateMenu: false,
    cupones: [],
    headers: [
      { text: 'Cupon', sortable: false, value: 'cupon', width: '25%' },
      { text: 'Cantidad', sortable: false, value: 'cantidad', align: 'right' },
      //   { text: 'Moneda', sortable: false, value: 'moneda' },
      { text: 'Tipo', value: 'porcentaje', align: 'center' },
      { text: 'Uso', sortable: false, value: 'unico', align: 'center' },
      { text: 'Vigencia', sortable: false, value: 'desde', align: 'center' },
      { text: 'Usos', sortable: false, value: 'usos', align: 'right' },
      { text: 'Activo', value: 'activo', align: 'center' },
      { text: 'Acciones', value: 'actions', sortable: false, width: '15%', align: 'center' },
    ],
    defaultItem: {
      _id: '',
      cupon: '',
      porcentaje: true,
      cantidad: null,
      unico: false,
      moneda: '',
      usos: 0,
      desde: null,
      hasta: null,
      activo: null,
    },
    editedItem: {
      _id: '',
      cupon: '',
      porcentaje: true,
      cantidad: null,
      unico: false,
      moneda: '',
      usos: 0,
      desde: null,
      hasta: null,
      activo: null,
    },
    fields: {
      Cupon: 'cupon',
      Cantidad: 'cantidad',
      Porcentaje: 'porcentaje',
      Único: 'unico',
      Desde: 'desde',
      Hasta: 'hasta',
    },
  }),
  mixins: [validationMixin],
  validations: {
    editedItem: {
      cupon: { required, minLength: minLength(8), maxLength: maxLength(20) },
      cantidad: { required, minLength: minLength(1), maxLength: maxLength(2) },
      desde: { required },
    },
  },
  computed: {
    //Titulo del dialogo
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Cupon' : 'Editar Cupon'
    },
    fromDateDisp() {
      return this.editedItem.desde
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },

    /**
     * @name maxDateDisp
     *
     * @description Calcula la fecha máxima para poder realizar una reserva
     * en este caso en particular un año posterior a la fecha de la reserva
     *
     * @return {date} fecha un año posterior a la fecha actual
     */
    maxDateDisp() {
      const date = new Date() //.toISOString().substr(0, 10)
      const year = date.getFullYear() + 1
      const month = date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + day
    },

    toDateDisp() {
      return this.editedItem.hasta
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },

    cuponErrors() {
      const errors = []
      if (!this.$v.editedItem.cupon.$dirty) return errors
      !this.$v.editedItem.cupon.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.cupon.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.cupon.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    cantidadErrors() {
      const errors = []
      if (!this.$v.editedItem.cantidad.$dirty) return errors
      !this.$v.editedItem.cantidad.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.cantidad.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.cantidad.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    desdeErrors() {
      const errors = []
      if (!this.$v.editedItem.desde.$dirty) return errors
      !this.$v.editedItem.desde.required && errors.push('Este campo es requerido')
      return errors
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerCupones()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('coupon/deleteCoupon', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir del dialogo
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si cambio algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Convierte una fecha a numeros formato 99999999
     */
    convierteFechaNumero(date) {
      const number = date.split('-')
      const year = number[0]
      const month =
        number[1].toString().length === 1 ? '0' + number[1].toString() : number[1].toString()
      const day =
        number[2].toString().length === 1 ? '0' + number[2].toString() : number[2].toString()
      const hoy = year.toString() + month + day.toString()
      return parseFloat(hoy)
    },

    /**
     * @description Convierte un numero formato 99999999 en una fecha
     */
    convierteNumeroFecha(date) {
      if (date == 99999999) {
        return null
      }
      const cadena = date.toString()
      let year = cadena.slice(0, 4)
      let month = cadena.slice(4, 6)
      let day = cadena.slice(6, 8)
      const fecha = year + '-' + month + '-' + day

      return fecha
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.cupones.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.cupones.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar cupones
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.cupones.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.desde = this.convierteNumeroFecha(item.desde)
      this.editedItem.hasta = this.convierteNumeroFecha(item.hasta)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter tecleado para saber si es un numero
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Evalua el caracter tecleado para saber si es un numero
     */
    isAlphaNumeric: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 65 || charCode > 90) &&
        (charCode < 97 || charCode > 122) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        console.log(charCode)
        evt.preventDefault()
      } else {
        return true
      }
    },

    obtenerCupones() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('coupon/fetchCoupons', payload)
        .then((response) => {
          this.cupones = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        console.log(this.editedItem.hasta)
        const desde = this.convierteFechaNumero(this.editedItem.desde)
        const hasta = this.editedItem.hasta
          ? this.convierteFechaNumero(this.editedItem.hasta)
          : 99999999
        let body = {
          cupon: this.editedItem.cupon,
          cantidad: this.editedItem.cantidad,
          desde: desde,
          hasta: hasta,
          porcentaje: this.editedItem.porcentaje,
          unico: this.editedItem.unico,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('coupon/editCoupon', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.cupones[this.editedIndex], this.editedItem)
        } else {
          // console.info(payload)
          this.$store
            .dispatch('coupon/addCoupon', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.cupones.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              console.log(err)
              this.$store.commit('ALERT', {
                color: 'error',
                text: err,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>

<style></style>
